@mixin vertical-horizontal-align($position: relative) {
  position: $position;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin listItem() {
  --padding-start: 0;
  --inner-padding-end: 0;
  --inner-padding-top: 0.5rem;
  --inner-padding-bottom: 0.5rem;
  --border-color: var(--default-border-color);
}
