@import 'functions';

.kp-popover {
  .popover-wrapper {
    width: 100%;
  }
  .popover-content {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    padding: toRem(50) toRem(20);
    text-align: center;
    border-radius: 1rem;
    width: 90%;
    margin: auto;
  }
  ion-backdrop {
    --backdrop-opacity: 0.7;
  }
}
