/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
// @import './styles/functions';
@import './functions/functions';
@import './variables/variables';
@import './mixins/mixins';
@import './overrides/badges';
@import './overrides/modals';
@import './overrides/popovers';
@import './overrides/action-sheets';
@import './ui/all';
@import './theme/dark-mode';

@font-face {
  font-family: 'DM Sans';
  src: url('../fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'DM Sans Bold';
  src: url('../fonts/DMSans-Bold.ttf');
}

@font-face {
  font-family: 'DM Sans Medium';
  src: url('../fonts/DMSans-Medium.ttf');
}

/* component custom styles */

:focus {
  outline: none;
}

ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}

ion-title {
  letter-spacing: -1.3px;
  font-family: var(--bold-font-family);
}

ion-header,
ion-footer {
  background: transparent;
}

ion-title.title-large {
  font-size: toRem(28);
  --padding-start: 0;
  padding-left: toRem(21);
  color: var(--ion-color-dark);
  font-family: var(--bold-font-family);
  letter-spacing: 0px;
  padding-bottom: 0px;
  font-weight: 700;
}

ion-back-button {
  --icon-font-size: 1.625rem;
  --icon-margin-start: 0.75rem;
  --icon-margin-end: 0.75rem;
  --min-width: 2.5rem;
  --min-height: 2.5rem; /*none was set*/
  --background: transparent;
  --color: var(--ion-color-dark);
}

.primary {
  color: var(--ion-color-primary);
}

ion-buttons fa-icon {
  padding-left: toRem(12);
  padding-right: toRem(12);
}

:root {
  ion-toolbar:last-of-type {
    --border-width: 0;
  }

  ion-input {
    border-radius: 1rem !important;
    border: 1px solid var(--ion-color-tertiary);
    height: toRem(45);
    --padding-start: 1rem;
    --padding-end: 1rem;
    letter-spacing: -0.4px;
    &:focus-within {
      border: 1px solid var(--ion-color-primary);
    }
    &.ng-touched.ng-invalid {
      border: 1px solid var(--ion-color-danger);
    }
  }
}

ion-grid,
.manual-input-button {
  padding-left: toRem(21);
  padding-right: toRem(21);
}

ion-footer {
  padding-left: toRem(20);
  padding-right: toRem(20);
}

ion-row {
  ion-col {
    padding-left: 0;
    padding-right: 0;
  }
}

ion-button {
  --border-radius: var(--default-app-border-radius);
  height: toRem(50);
  text-transform: none;
  font-size: toRem(20);
  font-family: var(--bold-font-family);
  --border-width: 2px;
  --background-activated: var(--ion-color-secondary);
  --ripple-color: var(--ion-color-secondary);
}

ion-button.button-block.button-disabled {
  --background: var(--ion-color-tertiary);
}

ion-button.button-outline {
  --background-activated: none;
  --color-activated: var(--ion-color-secondary);
}

ion-button.button-outline:active {
  --border-color: var(--ion-color-secondary);
}

ion-button.button-outline.button-disabled {
  --background: none;
  --border-color: var(--ion-color-tertiary);
  --color: var(--ion-color-tertiary);
}

ion-checkbox {
  --border-color: var(--ion-color-tertiary);
  --border-radius: 1rem;
  --checkmark-width: toRem(2.5);
  width: toRem(40);
  height: toRem(40);
}

fa-icon.end-icon {
  position: absolute;
  right: toRem(-5);
  font-size: toRem(16);
  line-height: toRem(20);
}

fa-icon.end-icon-textbox {
  font-size: toRem(16);
  width: toRem(24);
  height: toRem(24);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: toRem(8);
}

ion-label {
  font-family: var(--bold-font-family);
  color: var(--ion-color-medium);
  font-size: toRem(16);
}

ion-label a {
  text-decoration: underline;
}

ion-text {
  color: var(--ion-color-medium);
  font-size: toRem(16);
  line-height: toRem(24);
}

ion-text a {
  text-decoration: underline;
  font-family: var(--bold-font-family);
  letter-spacing: -0.6px;
}

ion-footer ion-toolbar {
  --padding-start: 0;
  --padding-end: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

ion-grid ion-row ion-col ion-item {
  --padding-start: 0;
  --padding-end: 0;
}

ion-card {
  border-radius: var(--default-app-border-radius);
  box-shadow: rgba(0, 0, 0, 0.12) 0px toRem(4) toRem(10);
}

/*end components*/

.dots {
  font-size: toRem(26);
  letter-spacing: toRem(-2.5);
  float: left;
  margin-right: toRem(7);
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bold-text {
  font-family: var(--bold-font-family);
}

.medium-font-text {
  font-family: var(--medium-font-family);
}

b {
  font-family: var(--bold-font-family);
  letter-spacing: -0.6px;
}

.text-size-12 {
  font-size: toRem(12) !important;
}

.text-size-14 {
  font-size: toRem(14) !important;
}

.text-size-16 {
  font-size: toRem(16) !important;
}

.text-size-18 {
  font-size: toRem(18) !important;
  letter-spacing: toRem(-0.5);
}

.text-size-24 {
  font-size: toRem(24) !important;
  line-height: toRem(24);
  letter-spacing: -0.6px;
}

.text-size-28 {
  font-size: toRem(28) !important;
  color: var(--ion-color-dark);
  font-family: var(--bold-font-family);
  letter-spacing: toRem(-1.5);
}

.text-size-22 {
  font-size: toRem(22) !important;
  letter-spacing: toRem(-0.5);
}

.text-size-20 {
  font-size: toRem(20) !important;
}

.text-size-32 {
  font-size: toRem(32) !important;
  letter-spacing: -1px !important;
}

.text-size-40 {
  font-size: toRem(40) !important;
  letter-spacing: -1px !important;
}

.text-size-45 {
  font-size: toRem(45) !important;
  letter-spacing: -1px !important;
}

.text-size-50 {
  font-size: toRem(50) !important;
  letter-spacing: -1px !important;
}

.text-size-64 {
  font-size: toRem(64) !important;
  letter-spacing: -1px !important;
}

.text-size-82 {
  font-size: toRem(82) !important;
  letter-spacing: -1px !important;
}

.lh-12 {
  line-height: toRem(12);
}

.lh-16 {
  line-height: toRem(16);
}

.lh-18 {
  line-height: toRem(18);
}

.z-1000 {
  z-index: 1000;
}

.red-text {
  color: var(--ion-color-danger);
}

.white-text {
  color: var(--ion-color-extrawhite) !important;
}

.black-text {
  color: var(--ion-color-extrawhite-contrast) !important;
}

.dark-text {
  color: var(--ion-color-dark) !important;
}

.medium-text {
  color: var(--ion-color-medium) !important;
}

.light-text {
  color: var(--ion-color-light) !important;
}

.kodypay-logo-container {
  width: toRem(132);
  height: toRem(30);
  margin-top: toRem(15);
}

.kodypay-logo-container-onboard {
  width: toRem(132);
  height: toRem(29);
  margin-top: toRem(50);
}

.kodypay-logo-blue {
  background: url('../images/icons/kodypay-logo-blue.svg') no-repeat;
}

.kodypay-logo-circle {
  width: toRem(60);
  height: toRem(60);
  background: url('../images/icons/kodypay-logo-circle.svg') no-repeat;
  background-size: cover;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.mtop4 {
  margin-top: toRem(4) !important;
}

.mtop5 {
  margin-top: toRem(5) !important;
}

.mtop6 {
  margin-top: toRem(6) !important;
}

.mtop8 {
  margin-top: toRem(8) !important;
}

.mtop10 {
  margin-top: toRem(10);
}

.mtop15 {
  margin-top: toRem(15) !important;
}

.mtop20 {
  margin-top: toRem(20) !important;
}

.mtop25 {
  margin-top: toRem(25);
}

.mtop30 {
  margin-top: toRem(30);
}

.mtop35 {
  margin-top: toRem(35);
}

.mtop40 {
  margin-top: toRem(40);
}

.mbottom5 {
  margin-bottom: toRem(5);
}

.mbottom8 {
  margin-bottom: toRem(8);
}

.mbottom10 {
  margin-bottom: toRem(10);
}

.mbottom15 {
  margin-bottom: toRem(15);
}

.mbottom20 {
  margin-bottom: toRem(20);
}

.mbottom30 {
  margin-bottom: toRem(30) !important;
}

.mleft5 {
  margin-left: toRem(5);
}

.mleft10 {
  margin-left: toRem(10);
}

.mleft21 {
  margin-left: toRem(21);
}

.mright5 {
  margin-right: toRem(5);
}

.mright10 {
  margin-right: toRem(10);
}

.mright21 {
  margin-right: toRem(21);
}

.ptop10 {
  padding-top: toRem(10);
}

.pleft10 {
  padding-left: toRem(10);
}

.ptop15 {
  padding-top: toRem(15);
}

.pad-left {
  padding-left: toRem(21);
}

.pad-bottom-10 {
  padding-bottom: toRem(10);
}

.p-0 {
  padding: 0;
}

.content-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content-vcenter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.content-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.content-hstart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.content-hend {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.inline-chevron-right {
  color: var(--ion-color-medium);
  font-size: toRem(20);
  margin-right: toRem(-10);
}

.input-icon {
  position: absolute;
  right: toRem(16);
  font-size: toRem(16);
  color: var(--ion-color-dark);
}

.not-available-item {
  text-decoration: line-through;
  opacity: 0.3;
}

.not-available {
  text-decoration: line-through;
  opacity: 0.3;
}

.border-top {
  border-top: 1px solid var(--default-border-color);
}

.border-bottom {
  border-bottom: 1px solid var(--default-border-color);
}

.bottom-align-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.align-top {
  align-self: flex-start;
}

.align-bottom {
  align-self: flex-end;
}

.checkout-icon {
  margin-left: toRem(20);
}

/**/
.multipage-modal-toolbar {
  padding-left: toRem(21) !important;
  padding-right: toRem(21) !important;
}

.multipage-modal-title-container {
  margin-top: toRem(20);
  border-bottom: 1px solid var(--default-border-color);
}

.multipage-modal-title {
  margin-bottom: 0;
  height: toRem(55);
  font-size: toRem(28) !important;
}

.multipage-modal-title-text {
  display: block;
  font-size: toRem(28) !important;
  line-height: toRem(35);
  letter-spacing: -1px;
  color: var(--ion-color-dark);
  font-family: var(--bold-font-family);
}

.multipage-modal-title-subtext {
  margin-top: toRem(3);
}

.multipage-modal-prev-button {
  color: var(--ion-color-dark);
  font-size: toRem(20);
  line-height: toRem(45);
}

.multipage-modal-next-button {
  color: var(--ion-color-dark);
  font-size: toRem(20);
  margin-right: toRem(-20);
}

.multipage-modal-title fa-icon {
  font-size: toRem(20);
  color: var(--ion-color-medium);
}

.multipage-modal-back-button {
  color: var(--ion-color-medium);
  font-size: toRem(16);
  margin-left: toRem(-20);
}

.modal-handle {
  position: absolute;
  top: toRem(15);
  z-index: 102;
  left: calc(50% - 2.2rem);
  width: toRem(70);
  height: toRem(5);
  border-radius: 0.16rem;
  background-color: var(--ion-color-extra-light);
}

.modal-container-class {
  --background: var(--ion-color-extrawhite);
  --backdrop-opacity: 1;
}

.modal-container-class .modal-wrapper {
  --border-radius: 1rem;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.modal-container-class ion-grid {
  width: 100%;
}

/*multipage end*/

.card-number-mask-dots {
  display: block;
  float: left;
  margin-right: toRem(5);
  font-size: toRem(24);
  letter-spacing: -1px;
}

.card-type-icon-container {
  width: toRem(34);
  height: toRem(30);
  color: var(--ion-color-extrawhite);
  font-size: toRem(30);
}

.ellipsis {
  @include ellipsis();
}

.alert-modal-title {
  margin-top: toRem(10);
  margin-bottom: 0;
  height: toRem(55);
  font-size: toRem(28) !important;
}

.alert-modal-close {
  font-size: toRem(20);
  color: var(--ion-color-medium);
  margin-right: toRem(5);
}

.border-divider-row {
  width: 100%;
  height: toRem(5);
  margin-bottom: toRem(5);
  border-bottom: 1px solid var(--default-border-color);
}

.no-border {
  border: 0 !important;
}

.transaction-row-container {
  width: 100%;
  height: toRem(80);
  border-bottom: 1px solid var(--default-border-color);
  line-height: toRem(14);
  padding-top: toRem(10);
}

.transaction-date {
  font-family: var(--bold-font-family);
  color: var(--ion-color-medium);
  font-size: toRem(16);
  letter-spacing: -0.4px;
}

.transaction-details {
  color: var(--ion-color-medium);
  font-size: toRem(16);
  letter-spacing: -0.4px;
}

.cameraOverlayDataContainer {
  position: absolute;
  bottom: toRem(100);
  width: 100%;
  padding-left: toRem(21);
  padding-right: toRem(21);
}

.bottom-activator-container {
  height: toRem(85);
  color: var(--ion-color-extrawhite);
  font-family: var(--bold-font-family);
  text-align: center;
  font-size: toRem(18);
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: toRem(30);
}

.text-right {
  text-align: right;
}

.item-description {
  line-height: toRem(10);
  font-family: var(--bold-font-family);
}

.half-width {
  width: calc(50% - 1rem);
}

.half-width fa-icon {
  margin-left: toRem(10);
  font-size: toRem(16);
}

/**sticky containers***/

.scroll-content-container {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  contain: size style layout;
  display: flex;
  flex-direction: column;
}

.align-to-top {
  margin-top: 0px;
}

.align-to-bottom {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  margin-bottom: toRem(10);
}

.p-absolute {
  position: absolute !important;
}

.p-relative {
  position: relative !important;
}

/*************UNFINISHED****************/
ion-app.cameraView,
body.cameraView,
ion-app.cameraView ion-content,
ion-app.cameraView .nav-decor {
  background: transparent none !important;
}

/******/

.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}

.d-none-not-important {
  display: none;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-center {
  align-items: center;
}

div[scrollx='true'],
div[scrolly='true'] {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .scroll-item {
    flex: 0 0 auto;
  }
}

div[scrollx='true'] {
  overflow-x: auto;
}

div[scrolly='true'] {
  overflow-y: auto;
}

.border-separator {
  width: 90%;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid var(--default-border-color);
  margin-top: toRem(10);
  margin-bottom: toRem(10);
}

.alert-modal-container .modal-wrapper {
  position: absolute;
  max-width: toRem(330);
  max-height: toRem(480);
  width: 90%;
  height: 80%;
  border-radius: 1rem !important;
  --box-shadow: 0 0 toRem(10) rgb(126, 126, 126);
}

.alert-modal-big-container .modal-wrapper {
  position: absolute;
  max-width: toRem(330);
  max-height: toRem(580);
  width: 90%;
  height: 90%;
  border-radius: 1rem !important;
  --box-shadow: 0 0 toRem(10) rgb(126, 126, 126);
}

ion-modal ion-backdrop {
  pointer-events: auto !important;
  --backdrop-filter: blur(toRem(10)) !important;
  backdrop-filter: blur(toRem(10)) !important;
  background: rgba(0, 0, 0, 0.65) !important;
}

ion-modal .modal-wrapper {
  --max-height: calc(100% - 7.5rem);
}

.payment-method-modal-class .modal-wrapper {
  height: 60%;
  top: 40%;
  position: absolute;
  display: block;
}

.process-payment-modal-class .modal-wrapper {
  height: 30%;
  top: 70%;
  position: absolute;
  display: block;
}

@media only screen and (device-width: toRem(375)) and (device-height: toRem(667)) and (-webkit-device-pixel-ratio: 2) {
  ion-modal .modal-wrapper {
    --max-height: 90%;
  }
}

.keyboard-offset {
  height: toRem(200);
}

footer-offset {
  height: toRem(80);
}

.input-border-radius {
  input:-webkit-autofill {
    border-radius: 1rem !important;
    height: 100%;
  }
}

.minus-1 {
  margin-top: -1px;
  margin-bottom: -1px;
}

.custom-input-icon {
  $root: &;
  --ion-background-color: transparent !important;
  --background-focused: transparent;
  --inner-border-width: 0px 0px 0px 0px;
  border-radius: 1rem !important;
  height: toRem(45);
  --padding-start: toRem(15);
  letter-spacing: -0.4px;

  &.special-border {
    border: 1px solid var(--ion-color-danger) !important;
  }

  ion-item {
    margin-right: toRem(-8);
    width: toRem(40);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ion-input {
    border-radius: 1rem !important;
    height: toRem(45);
    --padding: 0px;
    letter-spacing: -0.4px;
    border: 1px solid var(--ion-color-tertiary);

    &:focus-within {
      border: 1px solid var(--ion-color-primary);
    }
  }

  ion-datetime {
    width: 100%;
    border-radius: 1rem !important;
    height: toRem(45);
    --padding: 0px;
    --padding-start: toRem(15);
    --padding-top: toRem(13);
    letter-spacing: -0.4px;
    border: 1px solid var(--ion-color-tertiary);

    &:focus-within {
      border: 1px solid var(--ion-color-primary);
    }
  }

  input {
    padding-right: 0;
  }
}

.custom-searchbar .searchbar-input-container input {
  padding-inline-start: toRem(20) !important;
  height: toRem(45);
  font-size: toRem(21);
}

.custom-searchbar .searchbar-input-container button {
  display: none !important;
}

.without-bag {
  text-decoration: line-through;
  color: var(--ion-color-medium) !important;
}

.clickable {
  cursor: pointer;
  text-decoration: underline !important;
}

.marked-disabled-input input {
  opacity: 1 !important;
}

.qr-code-image-container-manual {
  position: absolute;
  top: toRem(59);
  left: toRem(75);
  width: toRem(46);
  height: toRem(70);
}

.secured-by-visa {
  width: toRem(152);
  padding: toRem(10);
  font-size: toRem(15);
  line-height: toRem(15);
  margin-left: auto;
  margin-right: auto;
}

credit-card {
  display: block;
  width: 100%;
  height: 100%;
}

.card-row {
  position: absolute;
  top: toRem(240);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: toRem(145);
  color: var(--ion-color-extrawhite);
}

.input-invalid-msg {
  color: var(--ion-color-warning);
  letter-spacing: -0.4px;
}

ion-toolbar {
  --ion-safe-area-top: 2.2rem;
}

.red {
  color: var(--ion-color-danger);
}

.green {
  color: var(--ion-color-success);
}

.icon-container {
  width: toRem(45);
  height: toRem(45);
  display: flex;
  align-items: center;
  justify-content: center;
}

// Loading controller.
.kp-loading {
  ion-spinner {
    transform: scale(3);
    color: var(--ion-color-primary) !important;
  }

  .loading-wrapper {
    background: none !important;
    backdrop-filter: none !important;
  }
}

// Fix the height of the CKO iframe loaded into the card-details-form component
iframe#singleIframe {
  height: 2.8125rem !important;
}
