.dark-mode {
  $bg: #000;
  $dark: #1c1c1c;
  $dark-subtitle: #c4c4c4;
  $dark-border: #272727;

  ::placeholder {
    color: var(--ion-text-color) !important;
    opacity: 1 !important;
  }

  kody-payment-page ion-header,
  kody-payment-page ion-toolbar,
  kody-order-confirmed-page,
  kody-restaurant-order-confirmed ion-toolbar,
  ion-content,
  ion-footer {
    background: $bg !important;
    --ion-background-color: #{$bg} !important;
    --ion-toolbar-background: #{$bg} !important;
  }

  restaurant-see-more-modal-page,
  restaurant-see-more-modal-page ion-footer,
  kody-shared-ui-modal ion-toolbar,
  kody-shared-ui-modal ion-header,
  kody-shared-ui-modal ion-footer,
  kody-item-information-modal ion-footer,
  .add-on-modal__content,
  .alert-wrapper,
  .modal-wrapper,
  .searchbar-input,
  .popover-content {
    background: $dark !important;
    --ion-background-color: #{$dark} !important;
    --ion-toolbar-background: #{$dark} !important;
  }

  h1,
  h3,
  h2,
  h4,
  input,
  ion-text,
  ion-back-button,
  kody-add-on-list li,
  ion-title.title-large,
  .card-number,
  .payment__notes__title,
  .order-cc__notes-title,
  .searchbar-search-icon,
  .order-notes__input,
  .alert-wrapper button .alert-radio-label,
  .add-on-modal__item-price {
    color: var(--ion-text-color) !important;
  }

  // Subtitles
  .payment__header-subtitle,
  .payment__subtitle,
  .menu-item__description,
  .order-cc__notes-subtitle,
  .payment__notes-subtitle,
  .label-wrapper h3,
  .no-data__subtitle,
  .kp-item--price,
  .alert-modal-subtitle {
    color: $dark-subtitle !important;
  }

  kody-customer-menu-page,
  .kp-item__controls-container,
  .payment-item__quantity,
  .payment__button,
  ion-chip.ion-color-primary {
    background: var(--primary-dark) !important;
  }

  kody-toggle-button {
    --toggle-on-background: var(--primary-dark);
    --toggle-off-background: var(--primary-dark);
  }

  .kp-item-weight-container,
  ion-badge.ion-color-secondary,
  ion-badge.ion-color-light,
  .kp-item-count {
    background: var(--primary-dark) !important;
    color: var(--ion-color-primary) !important;
  }

  .kp-item-border-divider,
  .add-on-modal {
    border-bottom: $dark-border 1px solid !important;
  }

  ion-item {
    --border-color: #272727 !important;
  }

  .kp-modal__close-btn {
    fa-icon {
      color: #fff !important;
    }
  }

  kody-card-details-form {
    ::placeholder {
      color: grey !important;
      opacity: 1 !important;
    }
  }

  .kody-input {
    --ion-text-color: #000;

    &.success {
      --ion-text-color: #fff;
    }

    &.error {
      --ion-text-color: #fff;
    }
  }
}
