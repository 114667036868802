@import 'media-queries';
@import 'functions';

@mixin kp-modal($max-height) {
  --max-height: #{$max-height};
  --border-radius: var(--default-app-border-radius) var(--default-app-border-radius) 0 0;
  &::part(content) {
    bottom: 0;
    position: absolute;
  }

  @media only screen and (min-width: $lg-breakpoint) {
    --border-radius: var(--default-app-border-radius);
    &::part(content) {
      top: 5vh;
    }
  }
}

.kp-modal-xs {
  @include kp-modal(20rem);
}

.kp-modal-sm {
  @include kp-modal(469px);
}

.kp-modal-md {
  @include kp-modal(30rem);
}

.kp-modal-lg {
  --height: 700px;
  @include kp-modal(80vh);
}
