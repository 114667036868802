@import 'functions';

.form-group {
  position: relative;
  display: block;
  margin-bottom: 1rem;

  &__error {
    position: absolute;
    bottom: -1.6rem;
    text-align: right;
    width: 100%;
    color: var(--ion-color-danger);
    font-weight: bold;
    margin: toRem(5) 0;
  }

  ion-label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--element-grey);
  }
}
