@import 'functions';

.kp-popover {
  &::part(backdrop) {
    --backdrop-opacity: 0.7;
  }

  &::part(content) {
    position: relative;
    width: unset;
    margin: 0 1rem;
    top: 0;
    left: 0;
    padding: toRem(50) toRem(20);
    text-align: center;
    border-radius: 1rem;
  }
}
