@mixin input-style($background, $focus-border) {
  background: $background;

  &:focus-within {
    border: 1px solid $focus-border;
  }
}

.kody-input {
  border-radius: var(--default-app-border-radius);
  border: none;

  &:focus-within::placeholder {
    opacity: 0.2 !important;
  }

  @include input-style(var(--ion-color-light), var(--ion-color-medium));

  &.success {
    @include input-style(var(--ion-color-success-contrast), var(--ion-color-success));
  }

  &.error {
    @include input-style(var(--ion-color-danger-contrast), var(--ion-color-danger));
  }
}
