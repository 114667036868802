@import 'media-queries';
@import 'functions';

@mixin kp-modal($max-height) {
  --max-height: #{$max-height};
  position: absolute;
  bottom: 0;
  border-radius: var(--default-app-border-radius) var(--default-app-border-radius) 0 0;

  @media only screen and (min-width: $lg-breakpoint) {
    position: absolute;
    top: 5vh;
    border-radius: var(--default-app-border-radius);
  }
}

.kp-modal-xs {
  .modal-wrapper,
  .modal-shadow {
    @include kp-modal(50vh);
  }
}

.kp-modal-sm {
  .modal-wrapper,
  .modal-shadow {
    @include kp-modal(469px);
  }
}

.kp-modal-md {
  .modal-wrapper,
  .modal-shadow {
    @include kp-modal(60vh);
  }
}

.kp-modal-lg {
  .modal-wrapper,
  .modal-shadow {
    @include kp-modal(80vh);
    height: 700px;
  }
}
