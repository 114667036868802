@import 'media-queries';
/*
* NOTE: Do not import this file into components etc as it will exceed the budgets set out in the Angular.json file
* All the variables here are css custom properties so will be available to all components
*/

/* Ionic Variables and Theming. For more info, please see:
 * http://ionicframework.com/docs/theming/
 */

/* iPhone X and Xs Max */
@media only screen and (min-device-width: toRem(375)) and (min-device-height: toRem(812)) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  /* styles */
  :root {
    --ion-safe-area-top: 2.5rem !important; /*was toRem(20)*/
    --ion-safe-area-bottom: 1.375rem !important;
  }
}

/* iPhone XR */
@media only screen and (min-device-width: toRem(414)) and (min-device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  /* styles */
  :root {
    --ion-safe-area-top: 2.5rem !important; /*was toRem(20)*/
    --ion-safe-area-bottom: 1.375rem !important;
  }
}

/** Ionic CSS Variables **/
:root {
  --ion-safe-area-top: 0.625rem;
  --ion-safe-area-bottom: 0.3rem;

  --ion-background-color: #fff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-toolbar-background: #fff;

  /* Set the font family of the entire app */
  --ion-font-family: DM Sans, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
  --medium-font-family: DM Sans Medium, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
  --ion-default-font: DM Sans, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;
  --bold-font-family: DM Sans bold, sans-serif;

  /** primary **/
  --ion-color-primary: #1566d1;
  --ion-color-primary-rgb: 21, 102, 209;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #125ab8;
  --ion-color-primary-tint: #2c75d6;

   /** Secondary color palette is not currently in use **/
  --ion-color-secondary: #ebf4ff;
  --ion-color-secondary-rgb: 99, 153, 224;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #5787c5;
  --ion-color-secondary-tint: #73a3e3;

  --ion-color-tertiary: #b8d1f1;
  --ion-color-tertiary-rgb: 184, 209, 241;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #a2b8d4;
  --ion-color-tertiary-tint: #bfd6f2;

  --ion-color-success: #219b55;
  --ion-color-success-rgb: 33, 155, 85;
  --ion-color-success-contrast: #e2fadc;
  --ion-color-success-contrast-rgb: 226, 250, 220;
  --ion-color-success-shade: #1d884b;
  --ion-color-success-tint: #37a566;

  --ion-color-warning: #d57c30;
  --ion-color-warning-rgb: 213, 124, 48;
  --ion-color-warning-contrast: #ffe8d0;
  --ion-color-warning-contrast-rgb: 255, 232, 208;
  --ion-color-warning-shade: #bb6d2a;
  --ion-color-warning-tint: #d98945;

  --ion-color-danger: #d53030;
  --ion-color-danger-rgb: 213, 48, 48;
  --ion-color-danger-contrast: #fadcdc;
  --ion-color-danger-contrast-rgb: 255, 235, 235;
  --ion-color-danger-shade: #bb2a2a;
  --ion-color-danger-tint: #d94545;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** extra-light **/
  --ion-color-extra-light: #e0e0e0;
  --ion-color-extra-light-rgb: 224, 224, 224;
  --ion-color-extra-light-contrast: #000000;
  --ion-color-extra-light-contrast-rgb: 0, 0, 0;
  --ion-color-extra-light-shade: #e0e0e0;
  --ion-color-extra-light-tint: #e0e0e0;

  --ion-color-extrawhite: #ffffff;
  --ion-color-extrawhite-rgb: 255, 255, 255;
  --ion-color-extrawhite-contrast: #000000;
  --ion-color-extrawhite-contrast-rgb: 0, 0, 0;
  --ion-color-extrawhite-shade: #e0e0e0;
  --ion-color-extrawhite-tint: #e0e0e0;

  /** custom colors **/
  --modal-background-color: #fff;
  --default-app-font-size: toRem(16);
  --default-app-border-radius: 15px;
  --default-border-color: #ebebeb;
  --store-item-border-radius: 20px;
  --default-chip-border-radius: 8px;
  --scanner-background: #194c9e;
  --card-background-red: #f24e7b;
  --card-shadow-color: #bebebe;
  --camera-background-color: #cccccc;
  --item-light-blue-background-color: #97b8e3;
  --list-item-border-color: #e9e9e9;
  --element-grey: #626262;
  --element-grey-contrast: #f2f2f2;
  --element-blue: #1566d1;
  --element-blue-contrast: #ebf4ff;
  --kody-header-image: url(../../images/menu/header.svg);

  --primary-bg: #ebf4ff;
  /* exposing breakpoint values for js */
  --sm-breakpoint: #{$sm-breakpoint};
  --md-breakpoint: #{$md-breakpoint};
  --lg-breakpoint: #{$lg-breakpoint};
  --xl-breakpoint: #{$xl-breakpoint};
}

/* iPhone X and Xs Max */
@media only screen and (min-device-width: 375px) and (min-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  /* styles */
  :root {
    --ion-safe-area-top: 2.5rem !important; /*was toRem(20)*/
    --ion-safe-area-bottom: 1.375rem !important;
  }
}

/* iPhone XR */
@media only screen and (min-device-width: 414px) and (min-device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  /* styles */
  :root {
    --ion-safe-area-top: 2.5rem !important; /*was toRem(20)*/
    --ion-safe-area-bottom: 1.375rem !important;
  }
}
