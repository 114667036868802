@import 'functions';
.iti .iti__country-list {
  min-width: 16rem;
}
input.int-tel-input {
  font-size: 1rem;
  border-radius: 0.5rem;
  padding: toRem(6) toRem(15);
  border: 1px solid #000;
  height: toRem(35);
}
.error input.int-tel-input {
  border-color: var(--ion-color-danger);
}

.inactive input.int-tel-input {
  border-color: var(--ion-color-light-shade);
}

.dark-mode input.int-tel-input {
  border-color: #fff !important;
  color: var(--ion-color-dark) !important;
}

.iti.iti--allow-dropdown {
  display: block;
  input[type='tel'] {
    width: 100%;
  }
}
